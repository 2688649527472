$(document).ready(function () {
	//Слайдер
    $("#Glide").glide({
        type: "carousel"
    });


    //Высота меню
    $menu = $('.menuCatalog__dropdown');
    $submenu = $('.dropdown__submenu');

    $submenu.height($menu.innerHeight());


    //Добавляем классы fixed при скроле
    $(window).scroll(function () {
        var header = $('header'),
            hiddenHeader = $('#hiddenHeader'),
            distance = header.innerHeight(),
            mobileHeader = $('.mobileHeader'),
            distanceMobile = mobileHeader.innerHeight();

        if ($(window).scrollTop() > distance) {
            hiddenHeader.css({display: 'none'});
            header.addClass('fixed');
        } else {
            hiddenHeader.css({display: 'block'});
            header.removeClass('fixed');
        }

        if ($(window).scrollTop() > distanceMobile) {
            mobileHeader.addClass('fixed');
        } else {
            mobileHeader.removeClass('fixed');
        }

    });

    //Мобильное меню
    $('.mobileHeader__hamburger').click(function () {
        var page = $('.page'),
            sidebar = $('.mobileSidebar');

        if (page.hasClass('active')) {
            page.removeClass('active');
            sidebar.removeClass('active');
        } else {
            page.addClass('active');
            sidebar.addClass('active');
        }
        
    });

    $('.catalog__sectionLink').click(function () {
        $(this).next('.catalog__subSection').addClass('active');
    });

    $('.back').click(function () {
        $(this).parent('.catalog__subSection').removeClass('active');
    });


    // Range for content block price
    var minPrice = $('#minPrice').val(),
        maxPrice = $('#maxPrice').val();

    $('.range').slider({
        range: true,
        min: 0,
        max: maxPrice,
        values: [minPrice, maxPrice],
        slide: function (event, ui) {
            $('#minPrice').val(ui.values[0]);
            $('#maxPrice').val(ui.values[1]);

            $('.range__min').text(ui.values[0]);
            $('.range__max').text(ui.values[1]);
        }
    });

    $('.range__min').text(minPrice);
    $('.range__max').text(maxPrice);

    // Range for filters
    $('.range-filters').each(function () {
        var rangeWr = $(this),
            range = rangeWr.children('.range-filters__line'),
            rangeMin = rangeWr.children('.range-filters__info').find('.range-filters__min'),
            rangeMax = rangeWr.children('.range-filters__info').find('.range-filters__max');

        range.slider({
            range: true,
            min: 0,
            max: rangeMax.val(),
            values: [rangeMin.val(), rangeMax.val()],
            slide: function (event, ui) {
                rangeMin.val(ui.values[0]);
                rangeMax.val(ui.values[1]);
            }
        });
    });


    //Filters accordion in sidebar

    $('.filters__title').click(function () {
        var section = $(this),
            content = section.next('.filters__content');

            if (content.hasClass('active')) {
                section.removeClass('active');
                content.removeClass('active');
            } else {
                section.addClass('active');
                content.addClass('active');
            }

    });


    // Flexslider

    $('#carousel').flexslider({
         animation: "slide",
         controlNav: false,
         animationLoop: false,
         slideshow: false,
         itemWidth: 20,
         maxItems: 3,
         itemMargin: 5,
         asNavFor: '#slider',
         customDirectionNav: $('.custom-navigation a')
     });

    $('#slider').flexslider({
         animation: "slide",
         controlNav: false,
         animationLoop: false,
         slideshow: false,
         sync: "#carousel",
         customDirectionNav: $('.navigation a')
     });

    $('.popup_link').magnificPopup({
      type: 'image',
      // delegate: 'a',
      gallery: {enabled: true}
});
    
});